import React, { useState } from "react";
import IUser from "../../types/IUser";
import {
  Box,
  Container,
  LinearProgress,
  Paper,
  Typography,
} from "@mui/material";
import { updateUser } from "../../services/user.service";
import useAlerts from "../../hooks/useAlerts";
import SettingsForm from "./components/SettingsForm";
import PasswordRecoveryForm from "./components/PasswordRecoveryForm";
import { getTokenUser } from "../../utils/tokenUtils";

export default function Settings(): JSX.Element {
  const { error, success } = useAlerts();
  const user = getTokenUser();
  const [data, setData] = useState<IUser>(user!);
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);

  function handleSubmit(data: IUser) {
    if (data.id) {
      setLoadingBtn(true);
      updateUser(data.id, data)
        .then(() => {
          success("Zaktualizowano użytkownika");
        })
        .catch((err) => {
          console.error(err);
          error("Nie można zaktualizować użytkownika");
        })
        .finally(() => {
          setLoadingBtn(false);
        });
    }
  }

  if (!data) {
    return (
      <Box>
        <Paper>
          <Box sx={{ p: 2 }}>
            <LinearProgress />
          </Box>
        </Paper>
      </Box>
    );
  }

  return (
    <Box>
      <Container maxWidth="lg">
        <Typography sx={{ mb: 3 }} variant="h4">
          Ustawienia
        </Typography>
        <Paper>
          {data && (
            <SettingsForm
              data={data}
              setData={setData}
              onSubmit={handleSubmit}
              loadingBtn={loadingBtn}
            />
          )}
        </Paper>
        <Paper sx={{ mt: 4 }}>
          <PasswordRecoveryForm data={data.id} />
        </Paper>
      </Container>
    </Box>
  );
}
