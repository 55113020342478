import ICompany from "../types/ICompany";
import IUser from "../types/IUser";

export const getIsAuthenticated = (): boolean =>
  sessionStorage.getItem("isAuthenticated")
    ? JSON.parse(sessionStorage.getItem("isAuthenticated") as string)
    : false;

export const setIsAuthenticated = (isAuthenticated: boolean): void =>
  sessionStorage.setItem("isAuthenticated", `${isAuthenticated}`);

export const getRefreshTokenExpirationTimestamp = (): number | undefined =>
  sessionStorage.getItem("refreshTokenExpirationTimestamp")
    ? Number(sessionStorage.getItem("refreshTokenExpirationTimestamp"))
    : undefined;

export const setRefreshTokenExpirationTimestamp = (timestamp: string): void =>
  sessionStorage.setItem("refreshTokenExpirationTimestamp", timestamp);

export const getTokenUser = (): IUser | null =>
  sessionStorage.getItem("user")
    ? JSON.parse(sessionStorage.getItem("user") as string)
    : null;

export const setTokenUser = (user: IUser | null): void =>
  sessionStorage.setItem("user", JSON.stringify(user));

export const getCompany = (): ICompany | null =>
  sessionStorage.getItem("company")
    ? JSON.parse(sessionStorage.getItem("company") as string)
    : null;

export const setCompany = (company: ICompany): void =>
  sessionStorage.setItem("company", JSON.stringify(company));

export const removeCompany = (): void => sessionStorage.removeItem("company");

export const clearSessionStorage = (): void => {
  sessionStorage.removeItem("isAuthenticated");
  sessionStorage.removeItem("company");
  sessionStorage.removeItem("user");
  sessionStorage.removeItem("refreshTokenExpirationTimestamp");
};
