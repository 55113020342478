export const paramFilterExtra =
    (params: Record<string, string | number>) =>
        Object.entries(params).reduce(
            (acc, [key, value], index) => `${acc}${index !== 0 ? "&" : ""}filter[${key}][%3D]=${"" + value}`
            , "")

export const paramFilter = (column: string, value: any, operator: string = '%3D',) => `&filter[${column}][${operator}]=${value}`;

export const paramSort = (column: string, sort: string = 'ASC') => `&sort[${column}]=${sort}`;

export const paramLimit = (param: number = 10) => `&limit=${param}`;

export const paramOffset = (param: number = 10) => `&offset=${param}`; 
