import React, { useState } from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import useAlerts from "../../hooks/useAlerts";
import { IPasswordRecovery } from "../../types/IUser";
import { updateUser } from "../../services/user.service";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";

export default function UserPassword({
    data
}: { data: number }) {

    const { error, success } = useAlerts()
    const [password, setPassword] = useState<IPasswordRecovery>();
    const [loadingBtn, setLoadingBtn] = useState<boolean>(false)

    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
        if (value !== password?.newPassword) {
            return false;
        }
        return true;
    });

    function handleChange(event: React.FormEvent<any>) {
        setPassword({
            ...password, [event.currentTarget.name]: event.currentTarget.value
        })
    }

    function handleSubmit(a: React.FormEvent) {


        if (password) {
            // delete password.repeatPassword;
            // console.log('haslo', password)


            // changePassword(password)
            //     .then((data) => {
            //         console.log('haslo zmienione', data);
            //         success('Zaktualizowano hasło');
            //     })
            //     .catch(err => {
            //         console.error(err);
            //         error('Nie można zaktualizować hasła');
            //     })

            const user = {
                id: data,
                password: password.newPassword
            }

            setLoadingBtn(true)

            updateUser(data, user)
                .then((data) => {
                    console.log('haslo zmienione', data);
                    success('Zaktualizowano hasło');
                })
                .catch(err => {
                    console.error(err);
                    error('Nie można zaktualizować hasła');
                })
                .finally(() => {
                    setLoadingBtn(false)
                })
        }
    }

    return <Box sx={{ p: 3, pt: 0, pb: 1 }}>
        <Typography color='#000000' sx={{ textTransform: 'uppercase', fontSize: '.8rem', fontWeight: '300' }}>
            Zmiana hasła
        </Typography>
        <ValidatorForm onSubmit={handleSubmit}>
            {/* <Box
                sx={{
                    display: 'none',
                    gridTemplateColumns: {
                        xs: 'repeat(1, 1fr)',
                        sm: 'repeat(3, 1fr)'
                    },
                    gap: 1,
                }}
                pb={4}
                pt={3}
            >
                <TextValidator
                    label="Obecne hasło"
                    name="oldPassword"
                    onChange={handleChange}
                    //value={password?.oldPassword || ""}
                    value={'pass'}
                    variant='outlined'
                    validators={['required']}
                    errorMessages={['Pole jest wymagane']}
                    type="password"
                    fullWidth
                />
            </Box> */}
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: {
                        xs: 'repeat(1, 1fr)',
                        sm: 'repeat(3, 1fr)'
                    },
                    gap: 1,
                }}
                pb={4}
                pt={3}
            >
                <TextValidator
                    label="Nowe hasło"
                    name="newPassword"
                    onChange={handleChange}
                    value={password?.newPassword || ""}
                    variant='outlined'
                    validators={['required']}
                    errorMessages={['Pole jest wymagane']}
                    type="password"
                    fullWidth
                />
                <TextValidator
                    label="Powtórz hasło"
                    name="repeatPassword"
                    onChange={handleChange}
                    value={password?.repeatPassword || ""}
                    variant='outlined'
                    validators={['isPasswordMatch', 'required']}
                    errorMessages={['Hasła nie są takie same', 'Pole jest wymagane']}
                    type="password"
                    fullWidth
                />
            </Box>
            <Grid>
                <Stack direction="row" pt={3} justifyContent="end">
                    <LoadingButton
                        sx={{ width: { xs: '100%', sm: '25%' } }}
                        disabled={!password?.repeatPassword}
                        loading={loadingBtn}
                        loadingPosition="start"
                        startIcon={<SaveIcon/>}
                        variant="outlined"
                        type={'submit'}
                    >
                        Aktualizuj
                    </LoadingButton>
                </Stack>
            </Grid>
        </ValidatorForm>
    </Box>
}