import axios, { AxiosPromise } from 'axios';
import IGroup, { IGroupUsers } from "../types/IGroup";

export const getAllGroups = (): AxiosPromise<[data: IGroup[], count: number]> => axios.get('/groups')

export const getGroupsParams = (params: string): AxiosPromise<[data: IGroup[], count: number]> => axios.get(`/groups?${params.substring(1)}`)

export const getOneGroup = (id: string | number): AxiosPromise<IGroup> => axios.get(`/groups/${id}`)

export const createGroup = (group: IGroup): AxiosPromise<IGroup> => axios.post(`/groups/`, group)

export const setUsersGroup = (id: string | number, users: IGroupUsers): AxiosPromise<IGroupUsers> => axios.post(`/groups/${id}/setUsers`, users)

export const updateGroup = (id: string | number, group: IGroup): AxiosPromise<IGroup> => axios.patch(`/groups/${id}`, group)

export const deleteGroup = (id: string | number): AxiosPromise => axios.delete(`/groups/${id}`)