import { Box } from "@mui/material";
import React from "react";
import TableGroups from "./components/tableGroups";

export default function Group(): JSX.Element {

    return <Box>
        {<TableGroups />}
    </Box>

}