import React, { useState } from "react";
import { Box, Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, TextField } from "@mui/material";
import { deleteEvent, updateEvent } from "../../../services/event.service";
import useAlerts from "../../../hooks/useAlerts";
import MUIDataTable from "mui-datatables";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { DateTimePicker, LocalizationProvider } from "@mui/lab";
import DateAdapter from '@mui/lab/AdapterMoment';
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";


export default function TableGroupEvents({
    data,
    setRefresh
}: { data: any, setRefresh: (refresh: boolean) => void }) {

    const [modalEvent, setModalEvent] = useState<boolean>(false);
    const [eventData, setEventData] = useState<any>();
    const [loadingBtn, setLoadingBtn] = useState<boolean>(false)
    const { error, success } = useAlerts();

    const [muiSettings] = useState({
        page: 0,
        rowsPerPage: 10,
        sortOrder: {},
    })

    const [muiData] = useState<any>(data);

    const columns = [
        {
            name: 'id',
            options: {
                display: false,
                filter: false,
                sort: false,
                viewColumns: false
            },
        },
        {
            name: 'name',
            label: 'Nazwa'
        },
        {
            name: 'startTime',
            label: 'Data rozpoczęcia',
            options: {
                customBodyRender: (value: any, tableMeta: any) => {
                    return (
                        <>
                            {new Date(tableMeta.rowData[2]).toLocaleDateString('pl-PL')} <strong>{new Date(tableMeta.rowData[2]).toLocaleTimeString(['pl-PL'], { hour: '2-digit', minute: '2-digit' })}</strong>
                        </>
                    )
                }
            }
        },
        {
            name: 'endTime',
            label: 'Data zakończenia',
            options: {
                customBodyRender: (value: any, tableMeta: any) => {
                    return (
                        <>
                            {new Date(tableMeta.rowData[3]).toLocaleDateString('pl-PL')} <strong>{new Date(tableMeta.rowData[3]).toLocaleTimeString(['pl-PL'], { hour: '2-digit', minute: '2-digit' })}</strong>
                        </>
                    )
                }
            }
        },
        {
            name: 'action',
            label: ' ',
            options: {
                filter: false,
                sort: false,
                viewColumns: false,
                customBodyRender: (value: any, tableMeta: any) => {
                    return (
                        <ButtonGroup orientation="horizontal"
                            aria-label="horizontal contained button group"
                            variant="text">
                            <Button onClick={() => { setEventData(muiData[tableMeta.rowIndex]); setModalEvent(true) }}>
                                Edycja
                            </Button>
                        </ButtonGroup>
                    )
                }
            }
        },
    ]

    const options = {
        filter: true,
        count: muiData.count,
        rowsPerPage: muiSettings.rowsPerPage,
        onRowsDelete: (rowsDeleted: any, newData: any) => {
            if (rowsDeleted.data) {
                rowsDeleted.data.map((row: any) => {
                    if (muiData) {
                        return deleteEvent(muiData[row.dataIndex].id)
                            .then(() => {
                                success('Usunięto wydarzenie');
                                return true
                            })
                            .catch(() => {
                                error('Nie można usunąć wydarzenia');
                                return false
                            })
                    } else {
                        return false
                    }
                })
            }
        },
        textLabels: {
            body: {
                noMatch: "Brak rekordów",
                toolTip: "Sortuj",
            },
            pagination: {
                next: "Kolejna strona",
                previous: "Poprzednia strona",
                rowsPerPage: "Wierszy na stronę:",
                displayRows: "z",
            },
            toolbar: {
                search: "Szukaj",
                downloadCsv: "Pobierz plik CSV",
                print: "Drukuj",
                viewColumns: "Zobacz kolumny",
                filterTable: "Filtruj tabelę",
            },
            filter: {
                all: "Wszystko",
                title: "FILTRY",
                reset: "RESET",
            },
            viewColumns: {
                title: "Pokaż kolumny",
                titleAria: "Pokaż/Ukryj kolumny",
            },
            selectedRows: {
                text: "Wiersze wybrane",
                delete: "Usuń",
                deleteAria: "Usuń wybrane wiersze",
            },
        },
        download: false,
        print: false
    };

    function handleChange(event: React.FormEvent<any>) {
        setEventData({ ...eventData, [event.currentTarget.name]: event.currentTarget.value })
    }

    function handleChangeSelect(event: SelectChangeEvent) {
        setEventData({ ...eventData, color: event.target.value as string })
    };

    function handleEditSubmit() {
        const dataToSend = {
            startTime: new Date(eventData?.startTime).toISOString(),
            endTime: new Date(eventData?.endTime).toISOString(),
            name: eventData?.name,
            color: eventData?.color
        }

        if (eventData.id) {
            setLoadingBtn(true)
            updateEvent(eventData.id, dataToSend)
                .then((data) => {
                    console.log('data received ', data)
                    success("Zapisano zmiany")
                    setEventData({})
                    setModalEvent(false);
                    setRefresh(true);
                })
                .catch((err) => {
                    console.error(err)
                    error()
                })
                .finally(() => {
                    setLoadingBtn(false)
                })
        } else {
            error()
        }

    }

    return <Box>
        <Paper>
            <Grid>
                <MUIDataTable
                    title='Wydarzenia'
                    data={muiData}
                    columns={columns}
                    options={options}
                />
            </Grid>
        </Paper>

        <Dialog open={modalEvent} onClose={() => { setModalEvent(false); setEventData({}) }}>
            <ValidatorForm
                onSubmit={handleEditSubmit}
            >
                <DialogTitle>
                    Edytujesz wydarzenie
                </DialogTitle>
                <DialogContent dividers>

                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(1, 1fr)',
                            gap: 5,
                        }}
                        mb={2}

                    >
                        <TextValidator
                            label="Nazwa wydarzenia"
                            onChange={handleChange}
                            name="name"
                            value={eventData?.name || ''}
                            variant={'standard'}
                            validators={['required']}
                            errorMessages={['Pole jest wymagane']}
                            fullWidth
                        />
                    </Box>
                    <LocalizationProvider dateAdapter={DateAdapter} locale={'plLocale'}>
                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(1, 1fr)',
                                gap: 5,
                            }}
                            mb={4}
                            pt={4}
                        >
                            <DateTimePicker
                                label="Data rozpoczęcia"
                                value={eventData?.startTime || ''}
                                onChange={(value) => {
                                    setEventData({ ...eventData, startTime: value })
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            <DateTimePicker
                                label="Data zakończenia"
                                value={eventData?.endTime || ''}
                                onChange={(value) => {
                                    setEventData({ ...eventData, endTime: value })
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </Box>
                    </LocalizationProvider>
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(1, 1fr)',
                            gap: 5,
                        }}
                        mb={2}

                    >
                        <FormControl fullWidth>
                            <InputLabel id="label_color">Rodzaj</InputLabel>
                            <Select
                                labelId="label_color"
                                id="color"
                                value={eventData?.color || ''}
                                label="Kolor"
                                onChange={handleChangeSelect}
                            >
                                <MenuItem value={'#f5655b'}>Egzamin</MenuItem>
                                <MenuItem value={'#0ACF83'}>Jazda</MenuItem>
                                <MenuItem value={'#29ffac'}>Szkolenie</MenuItem>
                                <MenuItem value={'#F44336'}>Inne</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button onClick={() => { setModalEvent(false); setEventData({}) }}>Wróć</Button>
                        <LoadingButton
                            loading={loadingBtn}
                            loadingPosition="start"
                            startIcon={<SaveIcon/>}
                            variant="outlined"
                            type={'submit'}
                        >
                            Zapisz
                        </LoadingButton>
                    </DialogActions>
                </DialogActions>
            </ValidatorForm>
        </Dialog>
    </Box>
}