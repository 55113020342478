import axios, { AxiosPromise } from "axios";
import { IElearning } from "../types/IElearning";
import { IElearningPage } from "../types/IElearningPage";

export const getElearnings = (): AxiosPromise<[data: IElearning[], count: number]> => axios.get(`/elearnings`)

export const getElearning = (id: string | number): AxiosPromise<IElearning> => axios.get(`/elearnings/${id}`)

export const createElearning = (data: IElearning): AxiosPromise => axios.post(`/elearnings`, data)

export const updateElearning = (id: string | number, data: IElearning): AxiosPromise => axios.patch(`/elearnings/${id}`, data)

export const createPage = (id: string | number, data: Partial<IElearningPage>): AxiosPromise => axios.post(`/elearnings/${id}/pages`)

export const deletePage = (elearningId: string | number, pageId: string | number): AxiosPromise => axios.delete(`/elearnings/${elearningId}/pages/${pageId}`)

export const updatePage = (elearningId: string | number, pageId: string | number, data: IElearningPage): AxiosPromise => axios.patch(`/elearnings/${elearningId}/pages/${pageId}`, data)

export const putPageImage = (elearningId: string | number, pageId: string | number, file: File): AxiosPromise => {
    const formData = new FormData();

    formData.append("file", file);

    return axios.put(`/elearnings/${elearningId}/pages/${pageId}/image`, formData);
}

export const putPageYoutube = (elearningId: string | number, pageId: string | number, link: string): AxiosPromise => {
    return axios.put(`/elearnings/${elearningId}/pages/${pageId}/youtube`, {link});
}

export const removePageImage = (elearningId: string | number, pageId: string | number): AxiosPromise => {
    return axios.delete(`/elearnings/${elearningId}/pages/${pageId}/image`);
}