import axios, {AxiosPromise} from 'axios';
import ICompany from "../types/ICompany";

export const getAllCompanies = (): AxiosPromise<[data: ICompany[], count: number]> => axios.get('/companies')

export const getOneCompany = (id: string | number): AxiosPromise<ICompany> => axios.get(`/companies/${id}`)

export const createCompany = (company: ICompany): AxiosPromise<ICompany> => axios.post(`/companies/`, company)

export const updateCompany = (id: string | number, company: ICompany): AxiosPromise<ICompany> => axios.patch(`/companies/${id}`, company)

export const deleteCompany = (id: string | number): AxiosPromise => axios.delete(`/companies/${id}`)

export const putCompanyImage = (id: string | number, file: File): AxiosPromise => {
    const formData = new FormData();

    formData.append("file", file);

    return axios.put(`/companies/${id}/image`, formData);
}