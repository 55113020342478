import React, { useState } from "react";
import { Box, Button, List, Stack, Typography } from "@mui/material";
import ICardItem from "../../../types/ICardItem";
import CardItem from "./CardItem";
import useAlerts from "../../../hooks/useAlerts";
import { createCardItem, deleteCardItem } from "../../../services/card.service";

export default function CardItemList({
    data,
    groupId,
    blocked
}: {
    data: ICardItem[],
    groupId: number,
    blocked: boolean
}) {
    const [items, setItems] = useState<ICardItem[]>(data || [])

    const { error, success } = useAlerts()

    function handleCreateItem() {
        createCardItem(groupId)
            .then(({ data }) => setItems([...items, data]))
            .catch(err => error('ups, coś poszło nie tak'))
    }

    function handleDelete(itemId: string | number) {
        deleteCardItem(itemId)
            .then(() => {
                setItems(items.filter(i => i.id !== itemId))
                success("Pomyślnie usunięto")
            })
            .catch(() => {
                error("Ups, coś poszło nie tak podczas usuwania")
            })
    }

    return <Box mt={2} mb={2}>
        <Typography gutterBottom variant={"subtitle1"}>Pytania</Typography>
        <List>
            {items.map((item, ix) => <CardItem
                key={ix}
                data={item}
                groupId={groupId}
                onDelete={() => handleDelete(item.id)}
                blocked={blocked} />)}
            {!blocked && (
                <Stack width={'100%'} direction={'row-reverse'}>
                    <Button onClick={() => handleCreateItem()}>
                        Dodaj pytanie
                    </Button>
                </Stack>
            )}
        </List>
    </Box>
}