import React from 'react';
import ReactDOM from 'react-dom';
import './themes/index.css';
import App from './containers/app/App';
import { BrowserRouter } from 'react-router-dom';
import axios from "axios";
import { BACKEND_URL } from "./config/config";
import { SnackbarProvider } from 'notistack';

axios.defaults.baseURL = BACKEND_URL;

ReactDOM.render(
    <React.StrictMode>
        <SnackbarProvider
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            maxSnack={3}
        >
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </SnackbarProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// reportWebVitals();
