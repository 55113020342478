import axios, {AxiosPromise} from "axios";
import ITest from "../types/ITest";
import ITestQuestion from "../types/ITestQuestion";

export const getTests = (): AxiosPromise<[data: ITest[], count: number]> => axios.get(`/tests`)

export const getTest = (id: string | number): AxiosPromise<ITest> => axios.get(`/tests/${id}`)

export const getTestsParams = (params: string): AxiosPromise<[data: ITest[], count: number]> => axios.get(`/tests?${params.substring(1)}`)

export const deleteTest = (id: string | number): AxiosPromise<ITest> => axios.delete(`/tests/${id}`)

export const createTest = (data: ITest): AxiosPromise<ITest> => axios.post(`/tests`, data)

export const createTestQuestion = (id: string | number, data: ITestQuestion): AxiosPromise<ITestQuestion> => axios.post(`/tests/${id}/questions`, data)

export const updateTest = (id: string | number, data: ITest): AxiosPromise<ITest> => axios.patch(`/tests/${id}`, data)

export const updateTestQuestion = (id: string | number, questionId: string | number, data: ITestQuestion): AxiosPromise<ITestQuestion> => axios.patch(`/tests/${id}/questions/${questionId}`, data)

export const deleteTestQuestion = (id: string | number, questionId: string | number): AxiosPromise<ITestQuestion> => axios.delete(`/tests/${id}/questions/${questionId}`)

export const putTestQuestionImage = (testId: string | number, questionId: string | number, file: File): AxiosPromise => {
    const formData = new FormData();

    formData.append("file", file);

    return axios.put(`/tests/${testId}/questions/${questionId}/image`, formData);
}

export const putTestQuestionYoutube = (testId: string | number, questionId: string | number, link: string): AxiosPromise => {
    return axios.put(`/tests/${testId}/questions/${questionId}/youtube`, {link});
}

export const removeTestQuestionImage = (testId: string | number, questionId: string | number): AxiosPromise => {
    return axios.delete(`/tests/${testId}/questions/${questionId}/image`);
}