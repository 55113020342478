import axios, {AxiosPromise} from 'axios'
import ICard from "../types/ICard";
import ICardGroup from "../types/ICardGroup";
import ICardItem from "../types/ICardItem";
import ICardMark from "../types/ICardMark";

export const createCard = (data: ICard) => axios.post(`/cards`, data);

export const getCard = (id: string | number) => axios.get(`/cards/${id}`)

export const getAllCards = (): AxiosPromise<[data: ICard[], count: number]> => axios.get('/cards')

export const updateCard = (id: string | number, card: ICard): AxiosPromise<ICard> => axios.patch(`/cards/${id}`, card)

export const deleteCard = (id: string | number): AxiosPromise => axios.delete(`/cards/${id}`)

export const createCardGroup = (cardId: string | number): AxiosPromise<ICardGroup> => axios.post(`/cards/${cardId}/groups`, {name: {}})

export const deleteCardGroup = (groupId: string | number, cardId: string | number = -1) => axios.delete(`/cards/${cardId}/groups/${groupId}`)

export const updateCardGroup = (groupId: string | number, data: ICardGroup, cardId: string | number = -1): AxiosPromise<ICardGroup> => axios.patch(`/cards/${cardId}/groups/${groupId}`, data)

export const createCardItem = (groupId: string | number): AxiosPromise<ICardItem> => axios.post(`/cards/groups/${groupId}/items`, {name: {}})

export const deleteCardItem = (itemId: string | number, groupId: string | number = -1) => axios.delete(`/cards/groups/${groupId}/items/${itemId}`)

export const updateCardItem = (itemId: string | number, data: ICardItem, groupId: string | number = -1): AxiosPromise<ICardItem> => axios.patch(`/cards/groups/${groupId}/items/${itemId}`, data)

export const createCardMark = (itemId: string | number): AxiosPromise<ICardMark> => axios.post(`/cards/items/${itemId}/marks`, {name: {}})

export const deleteCardMark = (markId: string | number, itemId: string | number = -1) => axios.delete(`/cards/items/${itemId}/marks/${markId}`)

export const updateCardMark = (markId: string | number, data: ICardMark, itemId: string | number = -1): AxiosPromise<ICardMark> => axios.patch(`/cards/items/${itemId}/marks/${markId}`, data)