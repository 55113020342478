import { useState } from "react";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import "./App.css";
import { useLocation, Route, Routes } from "react-router-dom";
import routes from "../../config/routes";
import {
  getCompany,
  getIsAuthenticated,
  getRefreshTokenExpirationTimestamp,
  getTokenUser,
  setCompany,
  setIsAuthenticated,
  setRefreshTokenExpirationTimestamp,
  setTokenUser,
} from "../../utils/tokenUtils";
import Login from "../auth/Login";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../themes";
import { CssBaseline } from "@mui/material";
import { DashboardNavbar } from "../../components/dashboard-navbar";
import { DashboardSidebar } from "../../components/dashboard-sidebar";
import { styled } from "@mui/material/styles";
import useAlerts from "../../hooks/useAlerts";
import axios from "axios";
import { authRefresh } from "../../services/auth.service";

const DashboardLayoutRoot = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  paddingTop: 64,
  [theme.breakpoints.up("lg")]: {
    paddingLeft: 280,
  },
}));

function App(): JSX.Element {
  const { warning } = useAlerts();
  const [isSidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();

  axios.interceptors.request.use(request => {
    request.withCredentials = true
    return request
  }, error => {
    Promise.reject(error)
  })

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (
        !error.response ||
        error.response?.status !== 401 ||
        !(
          error.response?.config?.url !== "/auth/refresh" &&
          error.response?.status !== 400
        )
      ) {
        return Promise.reject(error);
      }

      if (error.response.status === 401) {
        setLoading(true);

        const expirationTimestamp = getRefreshTokenExpirationTimestamp();
        const currentTokenUser = getTokenUser();
        const currentCompany = getCompany();

        if (
          expirationTimestamp &&
          Date.now() < expirationTimestamp &&
          currentTokenUser?.email
        ) {
          return authRefresh(currentTokenUser.email, currentCompany?.id)
            .then(({ data }) => {
              if (data.user) {
                setTokenUser(data.user);
                setIsAuthenticated(true);
              }

              if (data.company?.id) {
                setCompany(data.company);
              }

              if (data.refreshTokenExpirationTimestamp) {
                setRefreshTokenExpirationTimestamp(
                  data.refreshTokenExpirationTimestamp
                );
              }

              setLoading(false);

              return axios(error.config);
            })
            .catch((err) => Promise.reject(error));
        }
      }

      sessionStorage.clear();
      window.location.reload();
      warning("Sesja wygasła - zaloguj się ponownie");
    }
  );

  if (!getIsAuthenticated() && !getTokenUser()) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexGrow: 1,
            minHeight: "100%",
            height: "100vh",
          }}
          component="main"
        >
          <Login />
        </Box>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <DashboardLayoutRoot>
        <Box
          sx={{
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            width: "100%",
            mx: "auto",
            margin: 3,
          }}
          component="main"
        >
          <Routes location={location}>
            {routes.map(({ path, container: Xxx }, index) => (
              <Route key={index} path={path} element={<Xxx />}></Route>
            ))}
          </Routes>
          <Backdrop
            sx={{ color: "#fff", zIndex: (themes) => themes.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
      </DashboardLayoutRoot>
      <DashboardNavbar onSidebarOpen={() => setSidebarOpen(true)} />
      <DashboardSidebar
        onClose={() => setSidebarOpen(false)}
        open={isSidebarOpen}
      />
    </ThemeProvider>
  );
}

export default App;
