import React, {useEffect, useState} from "react";
import {Grid, Paper} from "@mui/material";
import {IElearning} from "../../types/IElearning";
import ElearningForm from "./components/ElearningForm";
import ElearningPages from "./components/ElearningPages";
import useAlerts from "../../hooks/useAlerts";
import {useParams} from "react-router-dom";
import {createElearning, getElearning, updateElearning} from "../../services/elearning.service";
import {R_ELEARNING_EDIT} from "../../config/routes";

export default function EditElearning() {

    const {error, success} = useAlerts()
    const {id} = useParams();

    const [data, setData] = useState<IElearning>({id: -1, name: "", pages: []});
    const [loadingBtn, setLoadingBtn] = useState<boolean>(false)

    function handleSubmit(data: IElearning) {
        setLoadingBtn(true)
        updateElearning(data.id, data)
            .then(({data: newData}) => {
                success('Szkolenie edytowane');
                setData({...data, ...newData})
            })
            .catch(err => {
                error('Nie można utworzyć szkolenia');
            })
            .finally(() => {
                setLoadingBtn(false)
            })
    }

    useEffect(() => {
        if (id) {
            setLoadingBtn(true)
            getElearning(id)
                .then(({data}) => {
                    setData(data)
                })
                .catch((err) => {
                    error("Ups, coś poszło nie tak :(")
                })
                .finally(() => {
                    setLoadingBtn(false)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    return <Grid container spacing={2}>
        <Grid item xs={12}>
            <Paper>
                <ElearningForm onSubmit={handleSubmit} data={data} setData={setData} loadingBtn={loadingBtn}/>
            </Paper>
        </Grid>
        <Grid item xs={12}>
            <Paper>
                <ElearningPages elearning={data}/>
            </Paper>
        </Grid>
    </Grid>
}