import axios, { AxiosPromise } from 'axios';
import IUser, { IPasswordRecovery } from "../types/IUser";

export const getAllUsers = (): AxiosPromise<[data: IUser[], count: number]> => axios.get('/users')

export const getOneUser = (id: string | number): AxiosPromise<IUser> => axios.get(`/users/${id}`)

export const getUsersParams = (params: string): AxiosPromise<[data: IUser[], count: number]> => axios.get(`/users?${params.substring(1)}`)

export const createUser = (user: IUser): AxiosPromise<IUser> => axios.post(`/users/`, user)

export const updateUser = (id: string | number, user: IUser): AxiosPromise<IUser> => axios.patch(`/users/${id}`, user)
export const changePassword = (passwords: IPasswordRecovery): AxiosPromise<IPasswordRecovery> => axios.post(`/auth/change-password/`, passwords)

export const deleteUser = (id: string | number): AxiosPromise => axios.delete(`/users/${id}`)