import React, { useEffect, useState } from "react";
import {
    Box, Button, ButtonGroup,
    Grid,
    Paper,
    Typography,
    SpeedDial,
    SpeedDialIcon,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import useAlerts from "../../../hooks/useAlerts";
import { R_GROUP_CREATE, R_GROUP_EDIT } from "../../../config/routes";
import { deleteGroup, getAllGroups } from "../../../services/group.service";
import ICompany from "../../../types/ICompany";
import { getAllCompanies } from "../../../services/company.service";
import { IGroupTables } from "../../../types/ITables";

export default function TableGroups() {

    const [companies, setCompanies] = useState<ICompany[]>();
    const navigate = useNavigate();
    const { error, success } = useAlerts();

    const [muiSettings] = useState({
        page: 0,
        rowsPerPage: 10,
        sortOrder: {},
    })

    const [muiData, setMuiData] = useState<IGroupTables>({
        count: 1,
        data: [],
    });

    const columns = [
        {
            name: 'id',
            options: {
                display: false,
                filter: false,
                sort: false,
                viewColumns: false
            },
        },
        {
            name: 'name',
            label: 'Nazwa'
        },
        {
            name: 'company',
            label: 'Firma',
            options: {
                customBodyRender: (value: any, tableMeta: any) => {
                    return (
                        <>
                            {companies?.find(x => x.id === tableMeta.rowData[2])?.name}
                        </>
                    )
                }
            }
        },
        {
            name: 'action',
            label: ' ',
            options: {
                filter: false,
                sort: false,
                viewColumns: false,
                customBodyRender: (value: any, tableMeta: any) => {
                    return (
                        <ButtonGroup orientation="horizontal"
                            aria-label="horizontal contained button group"
                            variant="text">
                            <Button component={Link} to={R_GROUP_EDIT("" + tableMeta.rowData[0])} key={tableMeta.rowData[0]}>
                                Edycja
                            </Button>
                        </ButtonGroup>
                    )
                }
            }
        },
    ]

    const options = {
        filter: true,
        count: muiData.count,
        rowsPerPage: muiSettings.rowsPerPage,
        onRowsDelete: (rowsDeleted: any, newData: any) => {
            if (rowsDeleted.data) {
                rowsDeleted.data.map((row: any) => {
                    if (muiData) {
                        return deleteGroup(muiData.data[row.dataIndex].id)
                            .then(() => {
                                success('Usunięto grupę');
                                return true
                            })
                            .catch(() => {
                                error('Nie można usunąć grupy');
                                return false
                            })
                    } else {
                        return false
                    }
                })
            }
        },
        textLabels: {
            body: {
                noMatch: "Brak rekordów",
                toolTip: "Sortuj",
            },
            pagination: {
                next: "Kolejna strona",
                previous: "Poprzednia strona",
                rowsPerPage: "Wierszy na stronę:",
                displayRows: "z",
            },
            toolbar: {
                search: "Szukaj",
                downloadCsv: "Pobierz plik CSV",
                print: "Drukuj",
                viewColumns: "Zobacz kolumny",
                filterTable: "Filtruj tabelę",
            },
            filter: {
                all: "Wszystko",
                title: "FILTRY",
                reset: "RESET",
            },
            viewColumns: {
                title: "Pokaż kolumny",
                titleAria: "Pokaż/Ukryj kolumny",
            },
            selectedRows: {
                text: "Wiersze wybrane",
                delete: "Usuń",
                deleteAria: "Usuń wybrane wiersze",
            },
        },
        download: false,
        print: false
    };

    useEffect(() => {
        getAllGroups()
            .then(({ data }) => {
                setMuiData({ count: data[1], data: data[0] });
            })
            .catch(err => {
                error('Nie można pobrać danych');
            })

        getAllCompanies()
            .then(({ data }) => {
                setCompanies(data[0])
            })
            .catch(err => {
                setCompanies([])
                console.error(err);
                error();
            })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <Box>
        <Paper>
            <Grid>
                <MUIDataTable
                    title={
                        <Typography variant="h6">
                            Grupy
                        </Typography>
                    }
                    data={muiData.data}
                    columns={columns}
                    options={options}
                />
            </Grid>
        </Paper>
        <SpeedDial
            ariaLabel="Dodaj grupę"
            sx={{ position: 'fixed', bottom: 16, right: 16, cursor: "pointer" }}
            icon={<SpeedDialIcon />}
            onClick={() => navigate(R_GROUP_CREATE)}
            open={false}
        >
        </SpeedDial>
    </Box>

}