import IMenuItem from "../types/MenuItem";
import { R_CARD, R_COMPANY, R_ELEARNING, R_INDEX, R_SETT, R_TEST, R_USER } from "./routes";

const menu: IMenuItem[] = [
    {
        name: "Home",
        route: R_INDEX
    },
    {
        name: "E-learning",
        route: R_ELEARNING
    },
    {
        name: "Testy",
        route: R_TEST
    },
    {
        name: "Karty jazdy",
        route: R_CARD
    },
    {
        name: "Użytkownicy",
        route: R_USER
    },
    {
        name: "Firmy",
        route: R_COMPANY
    },
    {
        name: "Ustawienia",
        route: R_SETT
    }
]

export default menu;