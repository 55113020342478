import React, {useState} from "react";
import ICompany from "../../types/ICompany";
import {Container, LinearProgress, Paper, Typography} from "@mui/material";
import CompanyForm from "./CompanyForm";
import {createCompany} from "../../services/company.service";
import {useNavigate} from 'react-router-dom';
import {R_COMPANY_EDIT} from "../../config/routes";
import useAlerts from "../../hooks/useAlerts";

export default function CreateCompany(): JSX.Element {

    const {error, success} = useAlerts();

    const [data, setData] = useState<ICompany>({
        address: "",
        city: "",
        email: "",
        id: 0,
        name: "",
        nip: "",
        phone: "",
        postalCode: "",
        groups: [],
        users: []
    });
    const [loadingBtn, setLoadingBtn] = useState<boolean>(false)

    const navigate = useNavigate();

    function handleSubmit(data: ICompany) {
        setLoadingBtn(true)

        createCompany(data)
            .then(({data}) => {
                navigate(R_COMPANY_EDIT(data.id))
                success('Firma dodana');
            })
            .catch((err) => {
                error('Nie można dodać firmy');
            })
            .finally(() => {
                setLoadingBtn(false)
            })
    }

    if (data === undefined) {
        return <Container maxWidth="lg">
            <Typography
                sx={{mb: 3}}
                variant="h4"
            >
                Tworzenie
            </Typography>
            <Paper>
                <LinearProgress/>
            </Paper>
        </Container>
    }

    return <Container maxWidth="lg">
        <Typography
            sx={{mb: 3}}
            variant="h4"
        >
            Tworzenie
        </Typography>
        {data && <CompanyForm data={data} setData={setData} onSubmit={handleSubmit} loadingBtn={loadingBtn}/>}
    </Container>
}