import { Autocomplete, Box, Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, LinearProgress, Paper, TextField, Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { R_USER_EDIT, R_USER_FROM_COMPANY_CREATE } from "../../../config/routes";
import useAlerts from "../../../hooks/useAlerts";
import { getGroupsParams, setUsersGroup } from "../../../services/group.service";
import { deleteUser, getUsersParams } from "../../../services/user.service";
import IGroup, { IGroupUsers } from "../../../types/IGroup";
import { IUserTables } from "../../../types/ITables";
import { paramFilter, paramSort } from "../../../utils/filterUtils";

export default function TableCompanyUsers(data: any) {

    //TODO: Typowanie
    const id = data.data;

    const [groups, setGroups] = useState<IGroup[]>([]);
    const [checked, setChecked] = useState<any>([]);
    const [promptOpen, setPromptOpen] = useState(false);
    const [users, setUsers] = useState<IGroupUsers>({
        users: []
    });
    const [dataGroups, setDataGroups] = useState<IGroup>();

    const { error, success } = useAlerts()

    const [muiSettings] = useState({
        page: 0,
        rowsPerPage: 10,
        sortOrder: {},
    })

    const [muiData, setMuiData] = useState<IUserTables>();

    const columns = [
        {
            name: 'id',
            options: {
                display: false,
                filter: false,
                sort: false,
                viewColumns: false
            },
        },
        {
            name: 'name',
            label: 'Nazwa',
            options: {
                sort: false
            }
        },
        {
            name: 'email',
            label: 'E-mail',
            options: {
                sort: false
            }
        },
        {
            name: 'phone',
            label: 'Telefon',
            options: {
                sort: false
            }
        },
        {
            name: 'groups',
            label: 'Grupy',
            options: {
                filter: false,
                customBodyRender: (value: any, tableMeta: any) => {
                    var something = '';

                    if (value.length > 1) {

                        value?.map((group: any, i: number, value: any) => {
                            if (i + 1 === value.length) {
                                something += groups?.find(x => x.id === group)?.name + ' ';
                            } else {
                                something += groups?.find(x => x.id === group)?.name + ', ';
                            }
                            return false
                        })
                    } else {
                        value?.map((group: any) => (something += groups?.find(x => x.id === group)?.name + ' '));
                    }

                    return something;

                }
            }
        },
        {
            name: 'action',
            label: ' ',
            options: {
                filter: false,
                sort: false,
                viewColumns: false,
                customBodyRender: (value: any, tableMeta: any) => {
                    return (
                        <ButtonGroup orientation="horizontal"
                            aria-label="horizontal contained button group"
                            variant="text">
                            <Button component={Link} to={R_USER_EDIT("" + tableMeta.rowData[0])} key={tableMeta.rowData[0]}>
                                Edycja
                            </Button>
                        </ButtonGroup>
                    )
                }
            }
        },
    ]

    const options = {
        filter: true,
        count: muiData?.count,
        rowsPerPage: muiSettings.rowsPerPage,
        onRowSelectionChange: (currentRowsSelected: any, allRowsSelected: any, rowsSelected: any) => {
            setChecked(allRowsSelected);
        },
        onRowsDelete: (rowsDeleted: any, newData: any) => {
            if (rowsDeleted.data) {
                rowsDeleted.data.map((row: any) => {
                    if (muiData) {
                        return deleteUser(muiData.data[row.dataIndex].id)
                            .then(() => {
                                success('Usunięto uczestnika');
                                return true
                            })
                            .catch(() => {
                                error('Nie można usunąć uczestnika');
                                return false
                            })
                    } else {
                        return false
                    }
                })
            }
        },
        textLabels: {
            body: {
                noMatch: "Brak rekordów",
                toolTip: "Sortuj",
            },
            pagination: {
                next: "Kolejna strona",
                previous: "Poprzednia strona",
                rowsPerPage: "Wierszy na stronę:",
                displayRows: "z",
            },
            toolbar: {
                search: "Szukaj",
                downloadCsv: "Pobierz plik CSV",
                print: "Drukuj",
                viewColumns: "Zobacz kolumny",
                filterTable: "Filtruj tabelę",
            },
            filter: {
                all: "Wszystko",
                title: "FILTRY",
                reset: "RESET",
            },
            viewColumns: {
                title: "Pokaż kolumny",
                titleAria: "Pokaż/Ukryj kolumny",
            },
            selectedRows: {
                text: "Wiersze wybrane",
                delete: "Usuń",
                deleteAria: "Usuń wybrane wiersze",
            },
        },
        download: false,
        print: false
    };

    function handlePrompt(open: boolean) {
        setPromptOpen(open);
    }

    function handleOpenPrompt() {

        let something = checked.map((a: any) => {
            return muiData?.data[a.index].id
        });

        setUsers({ users: something });
        setPromptOpen(true);

    }

    function handleAddToGroup() {
        if (dataGroups) {
            setUsersGroup(dataGroups.id, users)
                .then(() => {
                    success(`Dodano użytkowników do grupy "${dataGroups.name}"`)
                    setPromptOpen(false);

                    handleGetUsers();
                })
                .catch(err => {
                    console.error(err);
                    error(`Nie udało się dodać użytkowników do grupy "${dataGroups.name}"`);
                })
        } else {
            error();
            setPromptOpen(false);
        }
    }

    function handleChangeGroups(groups: IGroup) {
        setDataGroups(groups)
    }

    function handleGetUsers() {
        getUsersParams(paramFilter('company', id) + paramFilter('role', 'User') + paramSort('id', 'DESC'))
            .then(({ data }) => {
                setMuiData({ count: data[1], data: data[0] });
            })
            .catch(err => {
                setMuiData({ count: 0, data: [] });
                console.error(err);
                error();
            })
    }

    useEffect(() => {
        if (id) {
            handleGetUsers();

            getGroupsParams(paramFilter('company', id))
                .then(({ data }) => {
                    setGroups(data[0])
                })
                .catch(err => {
                    console.error(err);
                    error();
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    return (
        <Box
            mt={12}
            pb={2}
            sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
        >
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    m: -1
                }}
                pb={4}
            >
                <Typography
                    sx={{ m: 1 }}
                    variant="h5"
                >
                    Uczestnicy
                </Typography>
                <Box sx={{ m: 1 }}>
                    {muiData && muiData.count !== 0 && (
                        <Button
                            color="primary"
                            variant="contained"
                            component={Button}
                            onClick={() => {
                                handleOpenPrompt();
                            }}
                            disabled={checked?.length === 0}
                            sx={{ mr: 3 }}
                        >
                            Dodaj do grupy
                        </Button>
                    )}
                    <Button
                        color="primary"
                        variant="contained"
                        component={Link}
                        to={R_USER_FROM_COMPANY_CREATE(id)}
                    >
                        Dodaj uczestnika
                    </Button>
                </Box>
            </Box>
            <Paper>
                {!muiData && (
                    <Box p={2}>
                        <LinearProgress />
                    </Box>
                )}
                {muiData && muiData.count === 0 && (
                    <Box p={2}>
                        <Typography>Brak uczestników</Typography>
                    </Box>
                )}
                {muiData && muiData.count !== 0 && (
                    <Grid>
                        <MUIDataTable
                            title={''}
                            data={muiData.data}
                            columns={columns}
                            options={options}
                        />
                    </Grid>
                )}
            </Paper>
            <Dialog
                open={promptOpen}
                onClose={() => handlePrompt(false)}
            >
                <DialogTitle>
                    Do której grupy dodać ?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Chcesz dodać jednocześnie {users.users.length} użytkowników do grupy
                    </DialogContentText>
                    <Autocomplete
                        id="groups"
                        sx={{ mt: 4, mb: 2 }}
                        noOptionsText={'Brak grup'}
                        onChange={(event, newValue) => {
                            if (typeof newValue === "object" && newValue !== null) {
                                handleChangeGroups(newValue);
                            }
                        }}
                        options={groups ? groups : []}
                        getOptionLabel={(option: IGroup): string => {
                            return option.name ? option.name : '';
                        }}
                        renderOption={(props, groups) => {
                            return (
                                <li {...props} key={groups.id}>
                                    {groups.name}
                                </li>
                            );
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Dodaj grupy"
                                InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                }}
                            />
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handlePrompt(false)}>Nie</Button>
                    <Button onClick={() => handleAddToGroup()} autoFocus>Tak</Button>
                </DialogActions>
            </Dialog>
        </Box >
    )

}