import React, { useEffect, useState } from "react";
import {
    Box, Button, ButtonGroup,
    Grid,
    LinearProgress,
    Paper,
    SpeedDial,
    SpeedDialIcon,
    Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import useAlerts from "../../../hooks/useAlerts";
import { R_CARD_CREATE, R_CARD_EDIT } from "../../../config/routes";
import { ICardTables } from "../../../types/ITables";
import { deleteCard, getAllCards } from "../../../services/card.service";

export default function CardTable(): JSX.Element {

    const navigate = useNavigate();
    const { error, success } = useAlerts();

    const [muiSettings] = useState({
        page: 0,
        rowsPerPage: 10,
        sortOrder: {},
    })

    const [muiData, setMuiData] = useState<ICardTables>({
        count: 0,
        data: [],
    });

    const columns = [
        {
            name: 'id',
            options: {
                display: false,
                filter: false,
                sort: false,
                viewColumns: false
            },
        },
        {
            name: 'name',
            label: 'Nazwa'
        },
        {
            name: "blockedAt",
            label: "Edycja",
            options: {
                customBodyRender: (value: any, tableMeta: any) => {
                    return (
                        <Typography>
                            {tableMeta.rowData[2] ? 'Zablokowana' : 'Otwarta'}
                        </Typography>
                    )
                }
            }
        },
        {
            name: 'action',
            label: ' ',
            options: {
                filter: false,
                sort: false,
                viewColumns: false,
                customBodyRender: (value: any, tableMeta: any) => {
                    return (
                        <Box textAlign="right">
                            <ButtonGroup orientation="horizontal"
                                aria-label="horizontal contained button group"
                                variant="text">
                                <Button component={Link} to={R_CARD_EDIT("" + tableMeta.rowData[0])} key={tableMeta.rowData[0]}>
                                    Edycja
                                </Button>
                            </ButtonGroup>
                        </Box>
                    )
                }
            }
        },
    ]

    const options = {
        filter: true,
        count: muiData.count,
        rowsPerPage: muiSettings.rowsPerPage,
        onRowsDelete: (rowsDeleted: any, newData: any) => {
            if (rowsDeleted.data) {
                rowsDeleted.data.map((row: any) => {
                    if (muiData.data[row.dataIndex]?.blockedAt === null) {
                        return deleteCard(muiData.data[row.dataIndex].id)
                            .then(() => {
                                success('Usunięto kartę');
                                return true
                            })
                            .catch(() => {
                                error('Nie można usunąć karty');
                                return false
                            })
                    } else {
                        return false
                    }
                })
            }
        },
        textLabels: {
            body: {
                noMatch: "Brak rekordów",
                toolTip: "Sortuj",
            },
            pagination: {
                next: "Kolejna strona",
                previous: "Poprzednia strona",
                rowsPerPage: "Wierszy na stronę:",
                displayRows: "z",
            },
            toolbar: {
                search: "Szukaj",
                downloadCsv: "Pobierz plik CSV",
                print: "Drukuj",
                viewColumns: "Zobacz kolumny",
                filterTable: "Filtruj tabelę",
            },
            filter: {
                all: "Wszystko",
                title: "FILTRY",
                reset: "RESET",
            },
            viewColumns: {
                title: "Pokaż kolumny",
                titleAria: "Pokaż/Ukryj kolumny",
            },
            selectedRows: {
                text: "Wiersze wybrane",
                delete: "Usuń",
                deleteAria: "Usuń wybrane wiersze",
            },
        },
        download: false,
        print: false
    };

    useEffect(() => {
        getAllCards()
            .then(({ data }) => {
                setMuiData({ count: data[1], data: data[0] });
            })
            .catch(err => {
                error('Nie można pobrać danych');
            })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <Box>
        {muiData.count === 0 && (
            <Paper>
                <Box sx={{ p: 2 }}>
                    <LinearProgress />
                </Box>
            </Paper>
        )}
        {muiData.count !== 0 && (
            <Paper>
                <Grid>
                    <MUIDataTable
                        title={'Karty jazdy'}
                        data={muiData.data}
                        columns={columns}
                        options={options}
                    />
                </Grid>
            </Paper>
        )}
        <SpeedDial
            ariaLabel="Dodaj kartę"
            sx={{ position: 'fixed', bottom: 16, right: 16, cursor: "pointer" }}
            icon={<SpeedDialIcon />}
            onClick={() => navigate(R_CARD_CREATE)}
            open={false}
        >
        </SpeedDial>
    </Box>

}