import React, { useEffect, useState } from "react";
import {
    Box, Button, ButtonGroup,
    Grid,
    LinearProgress,
    Paper,
    Typography,
} from "@mui/material";
import { R_USER_EDIT } from "../../../config/routes";
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import useAlerts from "../../../hooks/useAlerts";
import { IUserTables } from "../../../types/ITables";
import { deleteUser, getUsersParams } from "../../../services/user.service";
import { paramFilter, paramSort } from "../../../utils/filterUtils";

export default function TableUserAdmins() {

    const { error, success } = useAlerts();

    const [muiSettings] = useState({
        page: 0,
        rowsPerPage: 10,
        sortOrder: {},
    })

    const [muiData, setMuiData] = useState<IUserTables>();

    const columns = [
        {
            name: 'id',
            options: {
                display: false,
                filter: false,
                sort: false,
                viewColumns: false
            },
        },
        {
            name: 'name',
            label: 'Nazwa'
        },
        {
            name: 'email',
            label: 'E-mail'
        },
        {
            name: 'phone',
            label: 'Telefon'
        },
        {
            name: 'action',
            label: ' ',
            options: {
                filter: false,
                sort: false,
                viewColumns: false,
                customBodyRender: (value: any, tableMeta: any) => {
                    return (
                        <ButtonGroup orientation="horizontal"
                            aria-label="horizontal contained button group"
                            variant="text">
                            <Button component={Link} to={R_USER_EDIT("" + tableMeta.rowData[0])} key={tableMeta.rowData[0]}>
                                Edycja
                            </Button>
                        </ButtonGroup>
                    )
                }
            }
        },
    ]

    const options = {
        filter: true,
        count: muiData?.count,
        rowsPerPage: muiSettings.rowsPerPage,
        onRowsDelete: (rowsDeleted: any, newData: any) => {
            if (rowsDeleted.data) {
                rowsDeleted.data.map((row: any) => {
                    if (muiData) {
                        return deleteUser(muiData.data[row.dataIndex].id)
                            .then(() => {
                                success('Usunięto użytkownika');
                                return true
                            })
                            .catch(() => {
                                error('Nie można usunąć użytkownika');
                                return false
                            })
                    } else {
                        return false
                    }
                })
            }
        },
        textLabels: {
            body: {
                noMatch: "Brak rekordów",
                toolTip: "Sortuj",
            },
            pagination: {
                next: "Kolejna strona",
                previous: "Poprzednia strona",
                rowsPerPage: "Wierszy na stronę:",
                displayRows: "z",
            },
            toolbar: {
                search: "Szukaj",
                downloadCsv: "Pobierz plik CSV",
                print: "Drukuj",
                viewColumns: "Zobacz kolumny",
                filterTable: "Filtruj tabelę",
            },
            filter: {
                all: "Wszystko",
                title: "FILTRY",
                reset: "RESET",
            },
            viewColumns: {
                title: "Pokaż kolumny",
                titleAria: "Pokaż/Ukryj kolumny",
            },
            selectedRows: {
                text: "Wiersze wybrane",
                delete: "Usuń",
                deleteAria: "Usuń wybrane wiersze",
            },
        },
        download: false,
        print: false
    };

    useEffect(() => {
        let isMounted = false;

        getUsersParams(
            paramFilter('role', 'Admin') +
            paramSort('id', 'DESC')
        )
            .then(({ data }) => {
                if (isMounted) return;

                setMuiData({ count: data[1], data: data[0] });
            })
            .catch(err => {
                error('Nie można pobrać danych');
            })

        return () => {
            isMounted = true;
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <Box>
        <Paper>
            {muiData && muiData.count !== 0 && (
                <Grid>
                    <MUIDataTable
                        title='Administratorzy'
                        data={muiData.data}
                        columns={columns}
                        options={options}
                    />
                </Grid>
            )}
            {!muiData && (
                <Box sx={{ p: 2 }}>
                    <LinearProgress />
                </Box>
            )}
            {muiData && muiData.count === 0 && (
                <Box sx={{ p: 2 }}>
                    <Typography>Brak administratorów</Typography>
                </Box>
            )}
        </Paper>
    </Box>

}