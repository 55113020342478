import React, { useEffect, useState } from "react";
import { Box, Grid, LinearProgress, Paper, Table, TableCell, TableHead, TableBody, TableContainer, TableRow, Typography } from "@mui/material";
import { getAllGroups } from "../../services/group.service";
import { getAllCompanies } from "../../services/company.service";

export default function TrainerGroups({
    data
}: { data: any }) {

    const [groups, setGroups] = useState<any>([]);
    const userData = data

    useEffect(() => {

        getAllCompanies()
            .then(({ data }) => {
                let companies = data[0]

                getAllGroups()
                    .then(({ data }) => {
                        userData.groups.map((groupId: number) => {
                            let findedGroup = data[0].find(x => x.id === groupId)

                            if (findedGroup?.company) {
                                findedGroup.company = companies.find(x => x.id === findedGroup?.company)
                            }

                            setGroups((groups: any) => [...groups, findedGroup])

                            return false
                        })
                    })
                    .catch(err => {
                        console.error(err);
                    })

            })
            .catch(err => {
                console.error(err);
            })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return <Box
        pt={4}
        pb={2}
        sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
    >
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                m: -1
            }}
            pb={4}
        >
            <Typography
                sx={{ m: 1 }}
                variant="h5"
            >
                Grupy
            </Typography>
            {/* <Box sx={{ m: 1 }}>
            <Button
                color="primary"
                variant="contained"
                component={Link}
                to={R_GROUP_FROM_COMPANY_CREATE(id)}
            >
                Dodaj grupę
            </Button>
        </Box> */}
        </Box>
        {console.log('grupa', groups)}
        <Paper>
            {!groups && (
                <Box p={2}>
                    <LinearProgress />
                </Box>
            )}
            {groups && groups.length === 0 && (
                <Box p={2}>
                    <Typography>Brak grup</Typography>
                </Box>
            )}
            {groups && groups.length > 0 && (
                <Grid container>
                    <Grid item style={{ width: '100%', whiteSpace: 'nowrap' }}>
                        <TableContainer>
                            <Table stickyHeader size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Nazwa</TableCell>
                                        <TableCell>Firma</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {groups.map((group: any, index: number) =>
                                        <TableRow key={index} hover>
                                            <TableCell>
                                                {group.name}
                                            </TableCell>
                                            <TableCell>
                                                {group.company.name}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            )}
        </Paper>
        {/* <Dialog
        open={promptOpen}
        onClose={() => handlePrompt(false)}
    >
        <DialogTitle>
            Usunąć wybraną grupę ?
        </DialogTitle>
        <DialogActions>
            <Button onClick={() => handlePrompt(false)}>Nie</Button>
            <Button onClick={() => promptTarget ? handleDelete(promptTarget) : ''} autoFocus>Tak</Button>
        </DialogActions>
    </Dialog>

    <Dialog open={modalEvent} onClose={() => { setModalEvent(false); setEventData({}) }}>
        <ValidatorForm
            onSubmit={handleSubmit}
        >
            <DialogTitle>
                Dodaj wydarzenie {pickGroup ? 'do grupy ' + pickGroup.name : ''}
            </DialogTitle>
            <DialogContent dividers>

                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(1, 1fr)',
                        gap: 5,
                    }}
                    mb={2}

                >
                    <TextValidator
                        label="Nazwa wydarzenia"
                        onChange={handleChange}
                        name="name"
                        value={eventData?.name || ''}
                        variant={'standard'}
                        validators={['required']}
                        errorMessages={['Pole jest wymagane']}
                        fullWidth
                    />
                </Box>
                <LocalizationProvider dateAdapter={DateAdapter} locale={'plLocale'}>
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(1, 1fr)',
                            gap: 5,
                        }}
                        mb={4}
                        pt={4}
                    >
                        <DateTimePicker
                            label="Data rozpoczęcia"
                            value={eventData?.startTime || ''}
                            onChange={(value) => {
                                setEventData({ ...eventData, startTime: value })
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                        <DateTimePicker
                            label="Data zakończenia"
                            value={eventData?.endTime || ''}
                            onChange={(value) => {
                                setEventData({ ...eventData, endTime: value })
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Box>
                </LocalizationProvider>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(1, 1fr)',
                        gap: 5,
                    }}
                    mb={2}

                >
                    <FormControl fullWidth>
                        <InputLabel id="label_color">Rodzaj</InputLabel>
                        <Select
                            labelId="label_color"
                            id="color"
                            value={eventData?.color || ''}
                            label="Kolor"
                            onChange={handleChangeSelect}
                        >
                            <MenuItem value={'#f5655b'}>Egzamin</MenuItem>
                            <MenuItem value={'#0ACF83'}>Jazda</MenuItem>
                            <MenuItem value={'#29ffac'}>Szkolenie</MenuItem>
                            <MenuItem value={'#F44336'}>Inne</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions>
                <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button onClick={() => { setModalEvent(false); setEventData({}) }}>Wróć</Button>
                    <Button variant="contained" type={'submit'}>Zapisz</Button>
                </DialogActions>
            </DialogActions>
        </ValidatorForm>
    </Dialog> */}

    </Box>
}