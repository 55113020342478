import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {Autocomplete, Box, Grid, Stack, TextField, Typography} from "@mui/material";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import {IElearning} from "../../../types/IElearning";
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import IGroup from "../../../types/IGroup";
import {getGroupsParams} from "../../../services/group.service";
import {paramSort} from "../../../utils/filterUtils";

export default function ElearningForm({
                                          data, setData, onSubmit, loadingBtn
                                      }: {
                                          loadingBtn: boolean,
                                          onSubmit: (data: IElearning) => void,
                                          data: IElearning,
                                          setData: Dispatch<SetStateAction<IElearning>> | Dispatch<SetStateAction<IElearning | undefined>>
                                      }
) {
    const [groups, setGroups] = useState<IGroup[]>();

    function handleSubmit() {
        onSubmit({...data})
    }

    function handleChange(event: React.FormEvent<any>) {
        setData({
            ...data, [event.currentTarget.name]: event.currentTarget.value
        })
    }

    function handleChangeGroups(groups: any) {
        setData({...data, 'groups': groups.map((x: IGroup) => x.id)})
    }

    function getGroupsFromId(sss: Number[]): IGroup[] {
        if (sss !== undefined) {
            return sss.reduce<IGroup[]>((acc, id) => {
                const x: IGroup | undefined = groups?.find(x => x.id === id)
                if (x !== undefined) acc.push(x);
                return acc;
            }, []);
        } else {
            return [];
        }
    }

    useEffect(() => {
        getGroupsParams(paramSort('company'))
            .then(({data}) => {
                const [x, count] = data;

                setGroups(x)
            })
            .catch(err => console.error(err))
    }, [])

    return <Box
        p={2}
        sx={{
            '& .MuiTextField-root': {m: 1, width: '40ch', mb: 3},
        }}
    >
        <ValidatorForm
            onSubmit={handleSubmit}
        >
            <Grid container>
                <Grid item>
                    <TextValidator
                        label="Nazwa"
                        onChange={handleChange}
                        name="name"
                        value={data.name || ""}
                        variant={'standard'}
                        validators={['required']}
                        errorMessages={['Pole jest wymagane']}
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <Autocomplete
                        multiple
                        id="groups"
                        noOptionsText={'Brak grup'}
                        groupBy={(groups) => groups.company}
                        onChange={(event, newValue) => {
                            if (typeof newValue === "object") {
                                handleChangeGroups(newValue);
                            }
                        }}
                        options={groups ? groups : []}
                        getOptionLabel={(option: IGroup): string => {
                            return option.name ? option.name : '';
                        }}
                        value={getGroupsFromId(data.groups as unknown as Number[]) || ''}
                        renderOption={(props, groups) => {
                            return (
                                <li {...props} key={groups.id}>
                                    {groups.name}
                                </li>
                            );
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Dodaj grupy"
                                InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                }}
                            />
                        )}
                    />
                    <Typography mt={0} mb={0} variant={'caption'}>Grupy dla których dostępne będzie szkolenie</Typography>
                </Grid>
            </Grid>
            <Grid>
                <Stack direction="row" mt={4} justifyContent="end" spacing={2}>
                    <LoadingButton
                        loading={loadingBtn}
                        loadingPosition="start"
                        startIcon={<SaveIcon/>}
                        variant="outlined"
                        type={'submit'}
                    >
                        Zapisz
                    </LoadingButton>
                </Stack>
            </Grid>
        </ValidatorForm>
    </Box>
}