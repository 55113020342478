import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import { DropResult } from 'react-beautiful-dnd';
import { Box, SpeedDial, SpeedDialIcon } from '@mui/material';
import DraggableList from './draggable/DraggableList';
import { getItems, reorder } from '../../utils/draggableUtils';

export default function Practic(): JSX.Element {

    const [items, setItems] = React.useState(getItems(10));

    const onDragEnd = ({ destination, source }: DropResult) => {
        if (!destination) return;

        const newItems = reorder(items, source.index, destination.index);

        setItems(newItems);
    };

    return <Box>
        <Paper>
            <DraggableList items={items} onDragEnd={onDragEnd} />
        </Paper>
        <SpeedDial
            ariaLabel="Dodaj"
            sx={{ position: 'fixed', bottom: 16, right: 16, cursor: "pointer" }}
            icon={<SpeedDialIcon />}
            onClick={() => console.log('wda')}
            open={false}
        >
        </SpeedDial>
    </Box>

}