import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import IGroup from "../../types/IGroup";
import {Box, Container, LinearProgress, Paper, Typography} from "@mui/material";
import GroupForm from "./GroupForm";
import {getOneGroup, updateGroup} from "../../services/group.service";
import useAlerts from "../../hooks/useAlerts";
import {R_GROUP} from "../../config/routes";
import TableGroupUsers from "./components/tableGroupUsers";
import {IUserTables} from "../../types/ITables";
import TableGroupEvents from "./components/tableGroupEvents";

export default function EditGroup(): JSX.Element {

    const {id} = useParams();
    const {error, success} = useAlerts()
    const navigate = useNavigate();
    const [data, setData] = useState<IGroup>();
    const [users, setUsers] = useState<IUserTables>({
        count: 0,
        data: [],
    });
    const [loadingBtn, setLoadingBtn] = useState<boolean>(false)
    const [events, setEvents] = useState<any>()
    const [refresh, setRefresh] = useState<boolean>(true)

    function handleSubmit(data: IGroup) {
        if (id) {
            setLoadingBtn(true)
            updateGroup(id, data)
                .then(() => {
                    success('Zaktualizowano grupę');
                })
                .catch(err => {
                    console.error(err);
                    error('Nie można zaktualizować grupy');
                })
                .finally(() => {
                    setLoadingBtn(false)
                })
        }
    }

    function handleRefresh(data: boolean) {
        setRefresh(false);

        if (data && id) {
            getOneGroup(id)
                .then(({data}) => {
                    setEvents(data.events)
                    setRefresh(true)
                })
                .catch((err) => {
                    navigate(R_GROUP);
                    console.log(err);
                    error('Nie ma takiej grupy');
                })
        }
    }

    useEffect(() => {
        if (id) {
            getOneGroup(id)
                .then(({data}) => {
                    setData(data);
                    if (Array.isArray(data.users) && data.users.length > 0) {

                        var filteredUsers = data.users.filter(function (user) {
                            return user.role !== "Trainer";
                        });
                        filteredUsers.sort((a: any, b: any) => (a.id < b.id) ? 1 : -1);

                        setUsers({count: filteredUsers.length, data: filteredUsers});
                    }

                    setEvents(data.events)
                })
                .catch((err) => {
                    navigate(R_GROUP);
                    console.log(err);
                    error('Nie ma takiej grupy');
                })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (data === undefined) {
        return <Box
        ><Paper>
            <Box sx={{p: 2}}>
                <LinearProgress/>
            </Box>
        </Paper>
        </Box>
    }

    return <Box>
        <Container maxWidth="lg">
            <Typography
                sx={{mb: 3}}
                variant="h4"
            >
                Edycja grupy
            </Typography>
            <Paper>
                {data && <GroupForm data={data} setData={setData} onSubmit={handleSubmit} loadingBtn={loadingBtn}/>}
            </Paper>

            {events && events.length !== 0 && refresh && <><Box p={4}></Box><TableGroupEvents data={events}
                                                                                              setRefresh={handleRefresh}/></>}
            {users.count > 0 && <><Box p={4}></Box><TableGroupUsers data={users}/></>}
        </Container>
    </Box>
}