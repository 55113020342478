import React, {useEffect, useState} from "react";
import CardForm from "./components/CardForm";
import {Alert, Box, Container, LinearProgress, Paper, Typography} from "@mui/material";
import {useParams} from "react-router-dom";
import {getCard, updateCard} from "../../services/card.service";
import CardGroupForm from "./components/CardGroupList";
import useAlerts from "../../hooks/useAlerts";
import ICard from "../../types/ICard";

export default function EditCard() {

    const {id} = useParams();

    const [data, setData] = useState<ICard>()
    const {error, success} = useAlerts()
    const [change, setChange] = useState<boolean>(false);
    const [blocked, setBlocked] = useState<boolean>(false);
    const [changeSwitch, setChangeSwitch] = useState<boolean>(false);
    const [loadingBtn, setLoadingBtn] = useState<boolean>(false)

    useEffect(() => {
        if (id) {
            setLoadingBtn(true)
            getCard(id)
                .then(({data}) => {
                    setData(data)
                    console.log(data)
                    setBlocked(!!data.blockedAt)
                })
                .catch((err) => {
                    console.log(err);
                    error();
                })
                .finally(() => {
                    setLoadingBtn(false)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    if (data == null || data.id === -1) {
        return <Box><Paper>
            <LinearProgress/>
        </Paper>
        </Box>
    }

    function handleSubmit(e: React.FormEvent) {
        if (id && data) {
            let sendData;

            if (changeSwitch) {
                sendData = {...data, blockedAt: new Date().toISOString()}
            } else {
                sendData = {...data}
            }

            updateCard(id, sendData)
                .then(() => {
                    success('Zaktualizowane dane');
                    setChange(false);
                })
                .catch(err => {
                    console.error(err);
                    error('Nie udało się zaktualizować danych');
                })

        }
    }

    function handleChange(e: React.FormEvent<any>) {
        setData({...data as ICard, [e.currentTarget.name]: e.currentTarget.value})
        setChange(true);
    }

    const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChangeSwitch(event.target.checked);
        setChange(true);
    };

    return <Box>
        <Container maxWidth="lg">
            <Typography
                sx={{mb: 3}}
                variant="h4"
            >
                Edycja karty
            </Typography>
            <Box mb={4}>
                <Paper>
                    <CardForm
                        data={data}
                        onSubmit={handleSubmit}
                        onChange={handleChange}
                        handleChangeSwitch={handleChangeSwitch}
                        changeSwitch={changeSwitch}
                        change={change}
                        loadingBtn={loadingBtn}
                    />
                </Paper>
            </Box>
            {blocked && (
                <Box mb={4}>
                    <Box>
                        <Alert severity="warning">Edycja zablokowana - karta jazdy została udostępniona</Alert>
                    </Box>
                </Box>
            )}
            <CardGroupForm
                cardId={data.id}
                data={(data as ICard).groups}
                blocked={blocked}
            />
        </Container>
    </Box>
}