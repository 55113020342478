import React, { useState } from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { IPasswordRecovery } from "../../../types/IUser";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import useAlerts from "../../../hooks/useAlerts";
import { changePassword } from "../../../services/user.service";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";

export default function PasswordRecoveryForm({
    data
}: { data: number | string }) {

    const { error, success } = useAlerts()
    const [password, setPassword] = useState<IPasswordRecovery>();
    const [loadingBtn, setLoadingBtn] = useState<boolean>(false)

    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
        if (value !== password?.newPassword) {
            return false;
        }
        return true;
    });

    function handleChange(event: React.FormEvent<any>) {
        setPassword({
            ...password, [event.currentTarget.name]: event.currentTarget.value
        })
    }

    function handleSubmit(a: React.FormEvent) {
        if (password) {
            delete password.repeatPassword;
            setLoadingBtn(true)

            changePassword(password)
                .then((data) => {
                    success('Zaktualizowano hasło');
                    setPassword({});
                })
                .catch(err => {
                    console.error(err);
                    error('Nie zaktualizowano hasła');
                    setPassword({});
                })
                .finally(() => {
                    setLoadingBtn(false)
                })
        }
    }

    return <Box p={2}>
        <Typography variant="h6" pb={4}>Zmiana hasła</Typography>
        <ValidatorForm
            onSubmit={handleSubmit}
        >
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gap: 1,
                }}
                mb={4}
            >
                <TextValidator
                    label="Obecne hasło"
                    name="oldPassword"
                    onChange={handleChange}
                    value={password?.oldPassword || ""}
                    variant='outlined'
                    validators={['required']}
                    errorMessages={['Pole jest wymagane']}
                    type="password"
                    fullWidth
                />
            </Box>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gap: 1,
                }}
                mb={4}
            >
                <TextValidator
                    label="Nowe hasło"
                    name="newPassword"
                    onChange={handleChange}
                    value={password?.newPassword || ""}
                    variant='outlined'
                    validators={['required']}
                    errorMessages={['Pole jest wymagane']}
                    type="password"
                    fullWidth
                />
                <TextValidator
                    label="Powtórz hasło"
                    name="repeatPassword"
                    onChange={handleChange}
                    value={password?.repeatPassword || ""}
                    variant='outlined'
                    validators={['isPasswordMatch', 'required']}
                    errorMessages={['Hasła nie są takie same', 'Pole jest wymagane']}
                    type="password"
                    fullWidth
                />
            </Box>
            <Grid>
                <Stack direction="row" mt={4} justifyContent="end">
                    <LoadingButton
                        disabled={!password?.repeatPassword}
                        loading={loadingBtn}
                        loadingPosition="start"
                        startIcon={<SaveIcon/>}
                        variant="outlined"
                        type={'submit'}
                    >
                        Aktualizuj
                    </LoadingButton>
                </Stack>
            </Grid>
        </ValidatorForm>
    </Box>
}