import React, { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import ICardGroup from "../../../types/ICardGroup";
import CardItemsList from "./CardItemsList";
import { updateCardGroup } from "../../../services/card.service";
import useAlerts from "../../../hooks/useAlerts";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";

export default function CardGroupItem({
  data,
  onDelete,
  cardId,
  blocked,
}: {
  data: ICardGroup;
  cardId: number;
  onDelete(groupId: number): void;
  blocked: boolean;
}) {
  const [group, setGroup] = useState<ICardGroup>(data);
  const [change, setChange] = useState<boolean>(false);
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);

  const { error, success } = useAlerts();

  function handleChangeName(lang: string) {
    return (event: React.FormEvent<any>) => {
      setGroup({
        ...group,
        name: { ...group.name, [lang]: event.currentTarget.value },
      });
      setChange(true);
    };
  }

  function handleChange(event: React.FormEvent<any>) {
    setGroup({
      ...group,
      [event.currentTarget.name]: event.currentTarget.value,
    });
    setChange(true);
  }

  function handleSubmit(e: React.FormEvent) {
    if (group && group.id) {
      setLoadingBtn(true);
      updateCardGroup(group.id, group, cardId)
        .then(() => {
          success("Zaktualizowane dane");
          setChange(false);
        })
        .catch((err) => {
          console.error(err);
          error("Nie udało się zaktualizować danych");
        })
        .finally(() => {
          setLoadingBtn(false);
        });
    }
  }

  useEffect(() => {
    // usuwa bug przy usuwaniu xD
    setGroup(data);
  }, [data]);

  return (
    <Accordion>
      <AccordionSummary>
        <Typography>Grupa: {group.name["pl"]}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <ValidatorForm onSubmit={handleSubmit}>
          <Grid container spacing={1} columnSpacing={1} rowSpacing={1}>
            <Grid item xs={10}>
              <TextValidator
                label="Nazwa PL"
                onChange={handleChangeName("pl")}
                name="name"
                value={group.name["pl"] || ""}
                variant={"outlined"}
                validators={["required"]}
                errorMessages={["Pole jest wymagane"]}
                fullWidth
                size={"small"}
              />
              <TextValidator
                label="Nazwa EN"
                onChange={handleChangeName("en")}
                name="name"
                value={group.name["en"] || ""}
                variant={"outlined"}
                validators={["required"]}
                errorMessages={["Pole jest wymagane"]}
                fullWidth
                size={"small"}
              />
              <TextValidator
                label="Nazwa DE"
                onChange={handleChangeName("de")}
                name="name"
                value={group.name["de"] || ""}
                variant={"outlined"}
                validators={["required"]}
                errorMessages={["Pole jest wymagane"]}
                fullWidth
                size={"small"}
              />
            </Grid>
            <Grid item xs={2}>
              <TextValidator
                label="Index"
                onChange={handleChange}
                name="index"
                value={group.index || 1}
                variant={"outlined"}
                validators={["required"]}
                type={"number"}
                errorMessages={["Pole jest wymagane"]}
                fullWidth
              />

              <Grid item xs={12}>
                <Stack width={"100%"} direction={"row-reverse"}>
                  <LoadingButton
                    disabled={!change}
                    loading={loadingBtn}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="outlined"
                    type={"submit"}
                  >
                    {change ? "Zapisz" : "Brak zmian"}
                  </LoadingButton>
                  <Button
                    disabled={blocked}
                    type={"button"}
                    onClick={() => onDelete(data.id)}
                    color={"error"}
                  >
                    Usuń grupę
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </ValidatorForm>
      </AccordionDetails>
      <AccordionDetails>
        <Divider />
        <CardItemsList
          data={group.items}
          groupId={group.id}
          blocked={blocked}
        />
      </AccordionDetails>
    </Accordion>
  );
}
