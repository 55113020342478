import { Box, CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { R_INDEX } from "../../config/routes";
import useAlerts from "../../hooks/useAlerts";
import { authLogout } from "../../services/auth.service";
import { clearSessionStorage } from "../../utils/tokenUtils";

export default function Logout(): JSX.Element {
  const navigate = useNavigate();
  const { error, info } = useAlerts();

  useEffect(() => {
    authLogout()
      .then((res) => {
        clearSessionStorage();
        navigate(R_INDEX);
        info("Wylogowano poprawnie");
      })
      .catch((err) => {
        error("Nie udało się wylogować");
      });
  });

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
}
