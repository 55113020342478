import { Box, SpeedDial, SpeedDialIcon } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { R_TEST_CREATE } from "../../config/routes";
import TableTest from "./components/TableTest";


export default function Test(): JSX.Element {

    const navigate = useNavigate();

    return <Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mb={4}>
            <TableTest />
        </Box>
        <SpeedDial
            ariaLabel="Dodaj Quiz"
            sx={{ position: 'fixed', bottom: 16, right: 16, cursor: "pointer" }}
            icon={<SpeedDialIcon />}
            onClick={() => navigate(R_TEST_CREATE)}
            open={false}
        >
        </SpeedDial>
    </Box >
}