import React, { useState } from "react";
import { Box, Container, Paper, Typography } from "@mui/material";
import CardForm from "./components/CardForm";
import useAlerts from "../../hooks/useAlerts";
import { useNavigate } from "react-router-dom";
import { R_CARD_EDIT } from "../../config/routes";
import ICard from "../../types/ICard";
import { createCard } from "../../services/card.service";

export default function CreateCard() {

    const [data, setData] = useState<ICard>({
        id: -1,
        name: "",
        blockedAt: null,
        groups: []
    })

    const [changeSwitch, setChangeSwitch] = useState<boolean>(false);
    const [loadingBtn, setLoadingBtn] = useState<boolean>(false)

    const { error, success } = useAlerts()
    const navigate = useNavigate();

    function handleSubmit() {
        setLoadingBtn(true)
        createCard(data)
            .then(({ data }) => {
                success("Utworzono kartę")
                navigate(R_CARD_EDIT(data.id))
            })
            .catch((err) => {
                error("Nie udało się utworzyć karty")
                console.error(err);
            })
            .finally(() => {
                setLoadingBtn(false)
            })
    }

    function handleChange(e: React.FormEvent<any>) {
        setData({ ...data, [e.currentTarget.name]: e.currentTarget.value })
    }

    const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChangeSwitch(event.target.checked);
    };

    return <Box>
        <Container maxWidth="lg">
            <Typography
                sx={{ mb: 3 }}
                variant="h4"
            >
                Nowa karta
            </Typography>
            <Paper>
                <CardForm
                    data={data}
                    onSubmit={handleSubmit}
                    onChange={handleChange}
                    handleChangeSwitch={handleChangeSwitch}
                    changeSwitch={changeSwitch}
                    change={true}
                    loadingBtn={loadingBtn}
                />
            </Paper>
        </Container>
    </Box>
}