import React, { useState } from "react";
import { Box, Button, ButtonGroup, Grid, Paper } from "@mui/material";
import { IUserTables } from "../../../types/ITables";
import { deleteUser } from "../../../services/user.service";
import useAlerts from "../../../hooks/useAlerts";
import { Link } from "react-router-dom";
import { R_USER_EDIT } from "../../../config/routes";
import MUIDataTable from "mui-datatables";

export default function TableGroupUsers({
    data
}: { data: IUserTables }) {

    const { error, success } = useAlerts();

    const [muiSettings] = useState({
        page: 0,
        rowsPerPage: 10,
        sortOrder: {},
    })

    const [muiData] = useState<IUserTables>(data);

    const columns = [
        {
            name: 'id',
            options: {
                display: false,
                filter: false,
                sort: false,
                viewColumns: false
            },
        },
        {
            name: 'name',
            label: 'Imię i nazwisko'
        },
        {
            name: 'email',
            label: 'E-mail'
        },
        {
            name: 'phone',
            label: 'Telefon'
        },
        {
            name: 'action',
            label: ' ',
            options: {
                filter: false,
                sort: false,
                viewColumns: false,
                customBodyRender: (value: any, tableMeta: any) => {
                    return (
                        <ButtonGroup orientation="horizontal"
                            aria-label="horizontal contained button group"
                            variant="text">
                            <Button component={Link} to={R_USER_EDIT("" + tableMeta.rowData[0])} key={tableMeta.rowData[0]}>
                                Edycja
                            </Button>
                        </ButtonGroup>
                    )
                }
            }
        },
    ]

    const options = {
        filter: true,
        count: muiData.count,
        rowsPerPage: muiSettings.rowsPerPage,
        onRowsDelete: (rowsDeleted: any, newData: any) => {
            if (rowsDeleted.data) {
                rowsDeleted.data.map((row: any) => {
                    if (muiData) {
                        return deleteUser(muiData.data[row.dataIndex].id)
                            .then(() => {
                                success('Usunięto użytkownika');
                                return true
                            })
                            .catch(() => {
                                error('Nie można usunąć użytkownika');
                                return false
                            })
                    } else {
                        return false
                    }
                })
            }
        },
        textLabels: {
            body: {
                noMatch: "Brak rekordów",
                toolTip: "Sortuj",
            },
            pagination: {
                next: "Kolejna strona",
                previous: "Poprzednia strona",
                rowsPerPage: "Wierszy na stronę:",
                displayRows: "z",
            },
            toolbar: {
                search: "Szukaj",
                downloadCsv: "Pobierz plik CSV",
                print: "Drukuj",
                viewColumns: "Zobacz kolumny",
                filterTable: "Filtruj tabelę",
            },
            filter: {
                all: "Wszystko",
                title: "FILTRY",
                reset: "RESET",
            },
            viewColumns: {
                title: "Pokaż kolumny",
                titleAria: "Pokaż/Ukryj kolumny",
            },
            selectedRows: {
                text: "Wiersze wybrane",
                delete: "Usuń",
                deleteAria: "Usuń wybrane wiersze",
            },
        },
        download: false,
        print: false
    };


    return <Box>
        <Paper>
            <Grid>
                <MUIDataTable
                    title='Uczestnicy'
                    data={muiData.data}
                    columns={columns}
                    options={options}
                />
            </Grid>
        </Paper>
    </Box>
}